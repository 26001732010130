import { render, staticRenderFns } from "./freeclinic_print.vue?vue&type=template&id=0cdd422e"
import script from "./freeclinic_print.vue?vue&type=script&lang=js"
export * from "./freeclinic_print.vue?vue&type=script&lang=js"
import style0 from "./freeclinic_print.vue?vue&type=style&index=0&id=0cdd422e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports