<template>
    <div id="mainboddy" v-html="html_data" style="width: 21cm;">
    </div>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.4.0/jspdf.umd.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/html2canvas/0.5.0-beta4/html2canvas.min.js"></script>
<script type="module">
    import pdfcrowd from 'https://cdn.jsdelivr.net/npm/pdfcrowd@5.14.0/+esm'
</script>
<script>
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
export default {
    created(){
        this.initial_get_print_page()
    },
    data(){
        return {
            html_data:''
        }
    },
    computed: {
        eventid() {
            return this.$route.params.eventid
        },
        pid() {
            return this.$route.params.pid
        },
    },
    methods:{
        initial_get_print_page(){
            if(this.eventid!=null&&this.eventid!=undefined&&this.pid!=null&&this.pid!=undefined){
                axios.get(process.env.VUE_APP_API_URL_HIS+'/fetchprintpage/'+this.eventid+'/'+this.pid)
                    .then(htmlContent => {
                        this.html_data = (htmlContent.data.data);
                    })
                    .then(() => {
                        this.initClickEvent()
                    })
                    .catch(error => {
                        console.error('Error fetching HTML:', error);
                    });
            }
        },
        initClickEvent(){
            let _this=this
            document.getElementById('generate-pdf').addEventListener('click', function() {
                var yes = confirm('你確定要儲存嗎？');
                if (yes) {
                    _this.$vs.loading()
                    const element = document.getElementById("mainboddy");
                    const printButtons =  element.querySelectorAll('.print');
                    printButtons.forEach(button => {
                        button.style.display = 'none';
                    });

                    html2canvas(element).then(canvas => {
                        console.log(element);
                        printButtons.forEach(button => {
                            button.style.display = ''; // Restore original display property
                        });

                        // document.body.appendChild(canvas);
                        const imgWidth = 210; // A4 size in mm
                        const imgHeight = (canvas.height * imgWidth) / canvas.width;
                        const pdf = new jsPDF('p','mm',[imgWidth,imgHeight]);
                            
                        pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, imgWidth, imgHeight,'free_clinic','FAST');
                        const pdfDataUri = pdf.output('datauristring');
                        // pdf.save('1.pdf')
                        console.log('pdf', pdf)
                        const pdfData64 = btoa(pdf.output()); // Get the PDF data as a string
                        axios.post(process.env.VUE_APP_API_URL_HIS+'/fetchSaveReport/'+_this.eventid+'/'+_this.pid, {
                            'data':pdfData64
                        })
                        .then(response => {
                            _this.$vs.loading.close()
                            alert('儲存成功')
                        }).catch((error)=>{
                        _this.$vs.loading.close()
                        })
                        // _this.$vs.loading.close()
                    }).catch((error)=>{
                        _this.$vs.loading.close()
                    });
                }
            });
        }
    }
}
</script>
<style>
@media print {
    @page {
        margin: 0.5cm 0.5cm 0 0;
        background-image: none;
        background: none; 
        -webkit-print-color-adjust: exact; 
    }

    body {
        margin: 0.5cm 0.5cm 0 0;
        background-image: none;
        background: none; 
    }
}
</style>